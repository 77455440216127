<!--
 * @Author: your name
 * @Date: 2022-03-16 10:06:35
 * @LastEditTime: 2022-08-19 10:49:30
 * @LastEditors: shmily
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson19\5_StoryPageOne.vue
-->
<template>
  <div class="story-page-one-container">
    <StoryPage :bgImg="bgImg" />
  </div>
</template>

<script>
import StoryPage from "@/components/Course/CoursePage/StoryPage";
export default {
  name: "StoryPageOne",
  components: {
    StoryPage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/13-Video/chapter-03/lesson-19-video-1.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.story-page-one-container {
  width: 100%;
  height: 100%;
}
</style>
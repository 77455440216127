<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "Tútu",
          hanzi: "图图",
          english: "",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-19/card-tutu.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "Xiǎo'ài",
          hanzi: "小爱",
          english: "",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-19/card-xiaoai.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "Dōngdōng",
          hanzi: "冬冬",
          english: "",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-19/card-dongdong.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "Xià xia",
          hanzi: "夏夏",
          english: "",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-19/card-xiaxia.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "Dōngdōng bàba",
          hanzi: "冬冬爸爸",
          english: "",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-19/card-baba.svg`),
        },
        {
          bgColor: 'redColor',
          order: "six",
          isShowImg: "true",
          currentNum: 6,
          pinyin: "Dōngdōng māma",
          hanzi: "冬冬妈妈",
          english: "",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-19/card-mama.svg`),
          blue: true,
          // longtext: true,
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>

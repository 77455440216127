<template>
  <div class="game-container">
    <RecognitionPinyinGame
      :optionList="optionList"
      :imgList="imgList"
      :titleInfo="titleInfo"
      :isFullScreen="true"
    />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "…… shì  nǎ guó rén?",
        hanzi: "……是哪国人？",
      },
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-3.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-4.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-5.svg"),
        },
      ],

      imgList: [
        {
          id: 2,
          pinying: "Jiānádà rén",
          hanzi: "加拿大人",
        },
        {
          id: 1,
          pinying: "Zhōngguó rén",
          hanzi: "中国人",
        },
        {
          id: 3,
          pinying: "Měiguó rén",
          hanzi: "美国人",
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>












<template>
  <div class="game-container">
    <MatchTheGroupCardsGame
      :imgList="imgGroupList"
      :buttonList="buttonList"
      @changeCardBG="changeCardBG"
    />
  </div>
</template>

<script>
import MatchTheGroupCardsGame from "@/components/Course/GamePage/MatchTheGroupCardsGame";

export default {
  data() {
    return {
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/menu-1-flag.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/menu-2-places.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/menu-3-map.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
      imgGroupList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/usa-flag.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/canada-flag.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/china.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/china-flag.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/usa.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/canada.svg"),
            type: 0,
            cardActive: false,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/china-place.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/canada.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/usa.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/china.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/usa-place.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/canada-place.svg"),
            type: 0,
            cardActive: false,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/usa.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/china-map.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/canada.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/canada-map.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/china.svg"),
            type: 0,
            cardActive: false,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-19/usa-map.svg"),
            type: 0,
            cardActive: false,
          },
        ],
      ],
    };
  },
  components: {
    MatchTheGroupCardsGame,
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        // 测试socket
        console.log(value);
        const data = {
          clickType: 3000101,
          data: { value },
          text: "introductoion页面侧边菜单栏按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickIntroAside", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickIntroAside");
  },
  methods: {
    changeCardBG(index, firstIndex, type, menuIndex) {
      this.imgGroupList[menuIndex][index].type = type;
      this.imgGroupList[menuIndex][index].cardActive = false;
      this.imgGroupList[menuIndex][firstIndex].type = type;
      this.imgGroupList[menuIndex][firstIndex].cardActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  position: relative;
  width: 100%;
  height: 100%;
  .aside {
    position: relative;
  }
}
</style>












<template>
  <div class="game-container">
    <SpeakingSentence :buttonList="buttonList" :gameList="gameList" :isClick="false"/>
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList:[
        {
          bgImg:require(`@/assets/img/16-GAMES/G06-drawing/lesson-19/image-ufo-1.svg`),
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G06-drawing/lesson-19/image-ufo-2.svg`),
        },
                {
          bgImg:require(`@/assets/img/16-GAMES/G06-drawing/lesson-19/image-ufo-3.svg`),
        },
                {
          bgImg:require(`@/assets/img/16-GAMES/G06-drawing/lesson-19/image-ufo-4.svg`),
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-19/menu-ufo-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-19/menu-ufo-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
                {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-19/menu-ufo-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
                {
          image: require("@/assets/img/16-GAMES/G06-drawing/lesson-19/menu-ufo-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
}
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>